import {
    Card,
    CardHeader,
    Body1,
    Caption1,
    makeStyles,
    CardFooter,
    Spinner,
    Skeleton,
    SkeletonItem,
    tokens,
    mergeClasses
} from "@fluentui/react-components";

import { fonts, useCardStyles } from "../styles";

const useStyles = makeStyles(
    {
        cardTitle: {
            ...fonts.cardTitle
        },
        cardSubtitle: {
            ...fonts.cardSubtitle
        }
    });



function StyledCard(props: any) {
    const { title, subtitle, className, footer, isLoading, error, actions } = props;

    const classes = useStyles();
    const cardClasses = useCardStyles();

    return (
        <>
            <Card className={cardClasses.cardBackground}>
                <CardHeader
                    header={
                        <div className={classes.cardTitle}>{title}</div>
                    }
                    description={<span className={classes.cardSubtitle}>{subtitle}</span>}
                    action={actions}
                     />
                {error && error}
                {!error && isLoading && <Skeleton> <SkeletonItem /> </ Skeleton>}
                {!error && !isLoading && props.children}
                {footer &&
                    <CardFooter>
                        {!error && isLoading && <Skeleton> <SkeletonItem /> </ Skeleton>}
                        {!error && !isLoading && footer}
                    </CardFooter>
                }
            </Card>
        </>
    );
}

export default StyledCard;