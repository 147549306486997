import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { teamsLightTheme, teamsDarkTheme } from "@fluentui/react-components";
import { LoggingProvider } from "./app/contexts/LoggingContext";
import Root from "./app/Root"
import ErrorPage from "./error-page";
import Assistance from "./app/assistance/page";
import Settings from "./app/settings/page";
import Notifications from "./app/notifications/page";
import Reporting from "./app/reports/page";
import TicketDetails from "./app/ticketDetails/page";
import CreateTicket from "./app/ticketCreate/page";
import Login from "./app/login/page";
import Welcome from "./app/welcome/page";
import "./index.css";
import RequestNewSubscription from "./app/subscriptions/RequestNewSubscription";
import LicensingPage from "./app/subscriptions/page";

const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
let theme = teamsLightTheme;

if (prefersDark) {
    theme = teamsDarkTheme;
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root prefersDark={prefersDark} />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Assistance />
            },
            {
                path: "/notifications",
                element: <Notifications />
            },
            {
                path: "/reports",
                element: <Reporting />
            },
            {
                path: "/ticketDetails/:ticketId",
                element: <TicketDetails />
            },
            {
                path: "/createTicket/",
                element: <CreateTicket />
            },
            {
                path: "/login",
                element: <Login />
            },
            {
                path: "/welcome",
                element: <Welcome />
            },
            {
                path: "/subscriptions",
                element: <LicensingPage />
            }, {
                path: "/settings",
                element: <Settings />
            },
            {
                path: "/subscriptions/request",
                element: <RequestNewSubscription />
            }
        ]
    },
]);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    //<StrictMode>
    <LoggingProvider>
        <RouterProvider router={router} />
    </LoggingProvider>
    //</StrictMode>
);

