import { useContext } from "react";
import { Badge, makeStyles, tokens } from "@fluentui/react-components";
import { Alert24Regular } from "@fluentui/react-icons";
import { NotificationsDataContext } from "../../app/contexts/NotificationsDataContext";

const useStyles = makeStyles({
    notificationIcon: {
        position: 'relative',
        width: '24px',
        height: '24px',
        display: 'inline-block',
        overflow: 'visible'
    },
    notificationBadge: {
        position: 'absolute',
        left: '13px',
        top: '10px',
        width: '10px',
        minWidth: '10px',

    }
});

export default function NotificationIcon() {

    const { notifications, isLoading } = useContext(NotificationsDataContext);

    const classes = useStyles();

    const showBadge = () => {

        const unResolvedNotifications = notifications.filter(n => n.status === 'Active');
        return unResolvedNotifications.length > 0;
    }

    return (
        <div className={classes.notificationIcon}>
            <div>
                <Alert24Regular />
                {
                    showBadge() &&
                    <Badge className={classes.notificationBadge} size='extra-small' color='danger' />
                }
            </div>
        </div>
    );
}