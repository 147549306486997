import { useEffect, useContext } from 'react';

import {
  makeStyles,
  tokens,
} from '@fluentui/react-components';

import { useMediaQuery } from 'react-responsive';

import OpenTickets from '../../components/tickets/OpenTickets'
import ClosedTickets from '../../components/tickets/ClosedTickets'
import OnHoldTickets from '../../components/tickets/OnHoldTickets'
import ErrorPage from '../../error-page';

import { LoggingContext } from '../contexts/LoggingContext';
import { AccountsDataContext } from '../contexts/AccountsDataContext';

const useStyles = makeStyles({
  supportRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '170px'
  },
  row: {
    marginTop: tokens.spacingVerticalXL
  },
  none: {

  }
});

const Assistance = () => {
  const classes = useStyles();
  const { trackPageView, getSessionId } = useContext(LoggingContext);
  const { accountError } = useContext(AccountsDataContext);

  useEffect(() => {
    // after everything has loaded track pageView
    trackPageView('assistance');
  }, []);

  return (
    <>
      {accountError && <ErrorPage sessionId={getSessionId()} />}

      {!accountError &&
        <div>
          <OpenTickets className={classes.none} />
          <div className={classes.row}>
            <OnHoldTickets className={classes.row} />
          </div>
          <div className={classes.row}>
            <ClosedTickets className={classes.row} />
          </div>
        </div>
      }
    </>
  );
}

export default Assistance;