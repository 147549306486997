
import { tokens, makeStyles } from '@fluentui/react-components';
import { Sparkline,ISparklineStyles, LineChart, ISparklineProps, DataVizPalette, IChartProps, ILineChartProps, getColorFromToken, DonutChart, IDonutChartProps, IChartDataPoint } from '@fluentui/react-charting';
import { useAppStyles } from '../styles';

export default function SparkLine(props: any) {
    const datapoints: any[] = props.data;
    const showLegend = props.showLegend;

    const latestValue = datapoints[datapoints.length - 1];
    const chartDataPoints = datapoints.map((datapoint: any, index: number) => {
        return {
            x: index,
            y: datapoint
        }
    })

    const chartData: IChartProps = {
        lineChartData: [
            {
                legend: latestValue.toString(),
                color: getColorFromToken(tokens.colorBrandForeground1),
                data: chartDataPoints
            },
        ],
    }

    const sparklineStyles: ISparklineStyles = {
        valueText: {
            fill: getColorFromToken(tokens.colorNeutralForeground1)
        }
    }

    return (<>
        <Sparkline  styles={sparklineStyles} data={chartData} showLegend={showLegend} valueTextWidth={40} width={80} />
    </>)
}