import { makeStyles, tokens, shorthands } from "@fluentui/react-components";

const fontBase = {
    fontFamily: tokens.fontFamilyBase,
    fontWeight: tokens.fontWeightRegular,
}

export const fonts = {
    title: {
        ...fontBase,
        fontSize: tokens.fontSizeBase400,
        lineHeight: tokens.lineHeightBase200,
    },
    body: {
        ...fontBase,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
    },
    body1Strong: {
        ...fontBase,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightSemibold,
    },
    label: {
        ...fontBase,
        fontWeight: tokens.fontWeightBold,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase200,
    },
    cardTitle: {
        ...fontBase,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
        lineHeight: tokens.lineHeightBase200,
    },
    cardSubtitle: {
        ...fontBase,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
    },
    tableHeader: {
        ...fontBase,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase300,
    },
    body1: {
        ...fontBase,
        fontWeight: tokens.fontWeightRegular,
        lineHeight: tokens.lineHeightBase300,
        fontSize: tokens.fontSizeBase300
    }
}

export const useAppStyles = makeStyles({
    appBackground: {
        backgroundColor: tokens.colorNeutralCardBackground
    }
});



export const useCardStyles = makeStyles({
    cardBackground: {
        backgroundColor: tokens.colorNeutralBackground1,
    }
});

export const useTableStyles = makeStyles({
    // Enforce consistent fonts for header and body.
    table: {
        '& th': {
            ...fonts.body1Strong,
        },
        '& td': {
            ...fonts.body
        }
    },
    rowClickable: {
        cursor: 'pointer'
    },
    truncatedTableData: {
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }

});