import { useState, useContext, useEffect } from 'react';

import {
  makeStyles,
  Spinner,
  Label,
  Button,
  tokens
} from '@fluentui/react-components';

import { useMediaQuery } from 'react-responsive';

import { Search20Regular } from '@fluentui/react-icons'

import { DatePicker } from '@fluentui/react-datepicker-compat'

import CasesOverview from '../../components/reporting/CasesOverview'
import TopTicketCategories from '../../components/reporting/TopTicketCategories';
import TopTicketSubmitters from '../../components/reporting/TopTicketSubmitters';
import TicketsByCategory from '../../components/reporting/TicketsByCategory';
import { TopTicketCategoryReport } from '../../types/reportingTypes';
import { ReportingDataContext, ReportingDataProvider } from '../contexts/ReportingDataContext';

import { LoggingContext } from '../contexts/LoggingContext';
import ErrorPage from '../../error-page';

interface TopTicketCategoriesProps extends TopTicketCategoryReport {
  topTicketCategoryReport: TopTicketCategoryReport;
}

const useStyles = makeStyles({
  actionsBar: {
    display: 'flex',
    justifyContent: 'right'
  },
  timeframeDatepicker: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  timeframeDatepickerMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  startDate: {
    marginRight: tokens.spacingHorizontalS
  },
  row: {
    marginBottom: '20px'
  },
  desktopRow: {
    height: '300px',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '20px'

  },
  mobileRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  spinner: {
    paddingTop: '180px'
  },
  reports: {
    marginTop: tokens.spacingVerticalXL,
    paddingBottom: '100px'
  },
  reportingContent: {
    minWidth: '280px'
  },
  cardSizeDesktop: {
    width: '30%'
  },
  cardSizeMobile: {
    marginBottom: '20px'
  }

});

// TODO retrieve using api possibly cache results for x minutes
const Reports = () => {

  const classes = useStyles()
  const { trackPageView, trackEvent, getSessionId } = useContext(LoggingContext);


  useEffect(() => {
    trackPageView('reports');
  }, []);


  const InnerComponent = () => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });

    const { reportingDetails, isLoading, reportingDates, setReportingDates, reportingError } = useContext(ReportingDataContext);
    const [startDate, setStartDate] = useState<Date | null | undefined>(reportingDates[0] ?? null);
    const [endDate, setEndDate] = useState<Date | null | undefined>(reportingDates[1] ?? null);

    const onDateRangeSubmit = () => {
      trackEvent('reportingSearch', { startDate, endDate });
      setReportingDates([startDate as Date, endDate as Date]);
    }

    const onStartDateChange = (date: Date | null | undefined) => {
      setStartDate(date);
      setReportingDates([date as Date, endDate as Date]);
    }

    const onEndDateChange = (date: Date | null | undefined) => {
      setEndDate(date);
      onDateRangeSubmit();
    }

    const mobileDatePicker = () => {
      return (
        <>
          <div className={classes.timeframeDatepickerMobile}>
            <div className={classes.startDate}>
              <DatePicker style={{ width: "130px" }} size='small' placeholder='Start date' value={startDate} onSelectDate={onStartDateChange} />
            </div>
            <div className={classes.startDate}>-</div>
            <div>
              <DatePicker style={{ width: "130px" }} size='small' placeholder='End date' value={endDate} onSelectDate={onEndDateChange} />
            </div>
          </div>
        </>)
    }

    const desktopDatePicker = () => {
      return (<div className={classes.timeframeDatepicker}>
        <div className={classes.startDate}>
          <Button onClick={onDateRangeSubmit} size='small' appearance='secondary' icon={<Search20Regular />} > </Button>
        </div>
        <div className={classes.startDate}>
          <DatePicker size='small' placeholder='Start date' value={startDate} onSelectDate={setStartDate} />
        </div>
        <div className={classes.startDate}>-</div>
        <div>
          <DatePicker size='small' placeholder='End date' value={endDate} onSelectDate={setEndDate} />
        </div>
      </div>)
    }

    return (
      <>
        {reportingError && <ErrorPage sessionId={getSessionId()} />}
        {!reportingError && isLoading && <div className={classes.spinner}><Spinner size='huge' /></div>}
        {
          !reportingError && !isLoading && reportingDetails &&
          <div className={classes.reportingContent}>
            <div className={classes.actionsBar}>
              <div>
                {isDesktopOrLaptop ? desktopDatePicker() : mobileDatePicker()}
              </div>
            </div>

            <div className={classes.reports}>
              <div className={isDesktopOrLaptop ? classes.desktopRow : classes.mobileRow}>
                <CasesOverview className={isDesktopOrLaptop ? classes.cardSizeDesktop : classes.cardSizeMobile} casesOverviewData={reportingDetails.ticketOverviewReport} />
                <TopTicketSubmitters className={isDesktopOrLaptop ? classes.cardSizeDesktop : classes.cardSizeMobile} highestTicketSubmitterData={reportingDetails.ticketsBySubmitterReport} maxRenderSize={isDesktopOrLaptop ? 5 : 10} />
                <TopTicketCategories className={isDesktopOrLaptop ? classes.cardSizeDesktop : classes.cardSizeMobile} topTicketCategoryReport={reportingDetails.ticketsByCategoryReport} maxRenderSize={isDesktopOrLaptop ? 5 : 10} />
              </div>
              {isDesktopOrLaptop &&
                <div className={classes.row}>
                  <TicketsByCategory casesOverTimeData={reportingDetails.ticketsOverTimeReport} />
                </div>
              }
            </div>
          </div>
        }</>
    );
  }

  return (
    <>
      <ReportingDataProvider>
        <InnerComponent />
      </ReportingDataProvider>
    </>
  );
}

export default Reports;