import { useState, useEffect, useContext } from "react";
import {
    Checkbox, Dialog, DialogSurface, DialogBody, DialogTrigger, Button, DialogActions, DialogTitle, DialogContent,
    Table, TableHeader, TableHeaderCell, TableBody, TableCellLayout, TableCell, TableRow,
    makeStyles,
    tokens,
    InfoLabel
} from "@fluentui/react-components";

import { AccountsDataContext } from '../contexts/AccountsDataContext';
import { LoggingContext } from "../contexts/LoggingContext";

import QuantityChange from "../../components/QuantityChange";
import Currency from "../../components/Currency";
import { EngageAppContext } from "../contexts/EngageAppContext";
import { LicensingDataContext } from "../contexts/LicensingDataContext";
import EngageTable from "../../components/EngageTable";

const useStyles = makeStyles({
    dialog: {
        maxWidth: '1000px'
    },
    productName: {
        width: '300px'
    },
    changeColumn: {
        display: 'flex',
        justifyItems: 'end',
    },
    agreement: {
        marginTop: tokens.spacingVerticalXL,
        marginBottom: tokens.spacingVerticalL,
        display: 'flex',
        alignItems: 'start'
    },
    agreementText: {
        marginTop: tokens.spacingVerticalXS
    }

});

export default function ConfirmationDialog(props: any) {
    const componentName = 'ConfirmationDialog';

    const { selectedAccount } = useContext(AccountsDataContext);
    const { isLoading} = useContext(EngageAppContext);
    const { trackException } = useContext(LoggingContext);
    const { updateSubscriptions } = useContext(LicensingDataContext);

    //const { updateSubscriptions } = useLicensingData();

    const classes = useStyles();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [modified, setModified] = useState<any[]>([]);
    const [agreementChecked, setAgreementChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { products } = props;

    useEffect(() => {

        let modifiedSubscriptions: any[] = [];

        for (var i = 0; i < products.length; i++) {
            var modifiedSubs = products[i].subscriptions.filter((x: any) => {
                return x.newQuantity && x.newQuantity != x.quantity;
            }
            )

            if (modifiedSubs.length > 0) {
                modifiedSubscriptions = [...modifiedSubscriptions, ...modifiedSubs];
            }

        }

        setErrorMessage("");
        setModified(modifiedSubscriptions);

    }, [products])

    const handleOnAgreementCheck = (ev: any, data: any) => {
        setAgreementChecked(data.checked)
    }

    const submitRequest = async () => {
        const tenantId = selectedAccount().tenantId;
        if (!tenantId)
            return;

        try {
            await updateSubscriptions(tenantId, modified);
            setIsOpen(false);
        }
        catch (err:any) {
            trackException(err);
            setErrorMessage(err.message);
        }

    }

    return (<>
        <Dialog open={isOpen}>
            <DialogTrigger><Button onClick={() => setIsOpen(true)}>Update Subscription(s)</Button></DialogTrigger>
            <DialogSurface className={classes.dialog}>
                <DialogBody>
                    <DialogTitle>Subscription Changes</DialogTitle>
                    <DialogContent>
                        <EngageTable>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell className={classes.productName}>Product</TableHeaderCell>
                                    <TableHeaderCell>Commit</TableHeaderCell>
                                    <TableHeaderCell>Billed</TableHeaderCell>
                                    <TableHeaderCell>Changes</TableHeaderCell>
                                    <TableHeaderCell>
                                        <InfoLabel
                                            info={
                                                <div>
                                                    <div>Commitment cost is the full cost of the subscription.</div>
                                                    <div>SKU price * Quantity * Commitment Term</div>
                                                </div>}>
                                            Commitment Cost
                                        </InfoLabel>
                                    </TableHeaderCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {
                                    modified.map((x: any) => {
                                        const commitmentTermModifier = x.commitmentTerm.term === '1-Year' ? 12 : 1;
                                        const calculatedCost = x.price * (x.newQuantity - x.quantity) * commitmentTermModifier;

                                        return (
                                            <TableRow key={x.subscription}>
                                                <TableCell className={classes.productName}>{x.subscriptionName}</TableCell>
                                                <TableCell>{x.commitmentTerm.term}</TableCell>
                                                <TableCell>{x.billingTerm}</TableCell>
                                                <TableCell><QuantityChange initialValue={x.quantity} updatedValue={x.newQuantity} /></TableCell>
                                                <TableCell><Currency dollarAmount={calculatedCost} /></TableCell>
                                            </TableRow>)
                                    })
                                }
                            </TableBody>
                        </EngageTable>

                        <div className={classes.agreement}>
                            <Checkbox
                                onChange={handleOnAgreementCheck}
                                label={<div>
                                    <div>I understand that licensing changes will incur additional charges.</div>
                                    <div>I acknowledge that I am authorized to request these changes on behalf of <strong>{selectedAccount().tenantName } </strong></div>
                                </div>} />


                        </div>
                        <div>
                            {errorMessage !== "" && <span>{ errorMessage }</span>} 
                        </div>
                    </DialogContent>

                </DialogBody>
                <DialogActions>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={() => setIsOpen(false)} appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <Button
                            disabled={!agreementChecked || isLoading(componentName)}
                            appearance="primary"
                            onClick={submitRequest}>Request Changes</Button>
                    </DialogActions>
                </DialogActions>
            </DialogSurface>
        </Dialog>
    </>)
}