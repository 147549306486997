import { useState, useEffect, useContext } from "react";
import {
    Dropdown, Combobox, Option, Label, Button, useId, makeStyles, tokens,
    Table, TableBody, TableHeader, TableHeaderCell, TableCell, TableRow, InfoLabel,
    Body1,
    useToastController, Toast, Toaster, ToastTitle, ToastBody, ToastFooter
} from "@fluentui/react-components";


import { Dismiss20Regular } from "@fluentui/react-icons";
import { dateUTC } from "../../../utils";
import { fonts } from "../../../styles";
import { EngageAppContext } from "../../contexts/EngageAppContext";
import { LicensingDataContext } from "../../../app/contexts/LicensingDataContext";
import EngageTable from "../../../components/EngageTable";
import { AccountsDataContext } from "../../../app/contexts/AccountsDataContext";

const useStyles = makeStyles({
    label: {
        marginTop: tokens.spacingVerticalL
    },
    option: {
        minWidth: '500px',
        maxHeight: '500px'
    },
    autoZeroTable: {
        marginTop: tokens.spacingVerticalL,
        width: '700px'
    },
    dismissColumn: {
        maxWidth: '25px',
        width: '25px'
    },
    renewalColumn: {
        width: '100px'
    },
    commitmentTermColumn: {
        width: '150px'
    },
    temp: {
        ...fonts.body1Strong
    }

})

export default function TrimSubscription() {
    const classes = useStyles();

    const { setSubscriptionAutoZeroStatus, getSubscriptions } = useContext(LicensingDataContext);
    const { accounts } = useContext(AccountsDataContext)

    const dropdownId = useId("dropdown-subscription");
    const [subscriptions, setSubscriptions] = useState<string[]>();
    const [zeroedSubscriptions, setZeroedSubscriptions] = useState([]);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string>("");

    /*   Toast   */

    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);

    const notify = (message: string) => dispatchToast(
        <Toast appearance="inverted">
            <ToastTitle>Error encountered</ToastTitle>
            <ToastBody>{message || 'Error Encountered'}</ToastBody>
        </Toast>
        , { position: 'top-end', timeout: 5000, intent: 'error' })

    const onSubscriptionSelected = async (event: any, data: any) => {
        setSelectedSubscriptionId(data.optionText);
        try {
            await setSubscriptionAutoZeroStatus(data.optionValue, true);
            const subs = await getSubscriptions();
            setSubscriptions(subs.filter((sub: any) => !sub.autoZero));
            setZeroedSubscriptions(subs.filter((sub: any) => sub.autoZero));
        }
        catch (err: any) {
            notify(err.message);
        }
        finally {
            setSelectedSubscriptionId("");
        }

    }

    const onRemovePressedFactory = (subscriptionId: string) =>
        async (event: any) => {
            try {
                await setSubscriptionAutoZeroStatus(subscriptionId, false)
                var updatedSubs = zeroedSubscriptions.filter((sub: any) => sub.subscriptionId != subscriptionId)
                setZeroedSubscriptions(updatedSubs);
            }
            catch (err: any) {
                notify(err.message);
            }
        }

    useEffect(() => {
        (async () => {

            const subscriptions = await getSubscriptions();
            setSubscriptions(subscriptions.filter((sub: any) => !sub.autoZero));
            setZeroedSubscriptions(subscriptions.filter((sub: any) => sub.autoZero));
        })()

    }, [accounts])
    return (
        <>
            <Toaster position="bottom-end" toasterId={toasterId} />
            <div className={classes.label}>
                <InfoLabel
                    id={dropdownId}
                    className={classes.temp}
                    info={
                        <div>
                            <p>Every billing cycle, Engage by Summit Technology will trim excess licensing to match the number you are using</p>
                        </div>
                    }>Trim excess licensing on renewal</InfoLabel>
            </div>
            <div className={classes.autoZeroTable}>
                <EngageTable>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Subscription</TableHeaderCell>
                            <TableHeaderCell className={classes.commitmentTermColumn}>Commitment Term</TableHeaderCell>
                            <TableHeaderCell className={classes.renewalColumn}>Next Renewal</TableHeaderCell>
                            <TableHeaderCell className={classes.dismissColumn}></TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {zeroedSubscriptions.map((x: any) => {
                            return (

                                <TableRow key={x.subscriptionId}>
                                    <TableCell><Body1>{x.productName}</Body1></TableCell>
                                    <TableCell className={classes.commitmentTermColumn}>
                                        <Body1>{x.commitmentTerm}</Body1></TableCell>
                                    <TableCell className={classes.renewalColumn}><Body1>{dateUTC(x.renewalDate)}</Body1></TableCell>
                                    <TableCell className={classes.dismissColumn}>
                                        <Button
                                            id={x.subscriptionId}
                                            appearance="transparent"
                                            icon={<Dismiss20Regular />}
                                            onClick={onRemovePressedFactory(x.subscriptionId)}></Button>
                                    </TableCell>
                                </TableRow>

                            )

                        })
                        }
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Dropdown
                                    placeholder="Add subscription to autozero"
                                    positioning="after-top"
                                    aria-labelledby={dropdownId}
                                    listbox={{ className: classes.option }}
                                    value={selectedSubscriptionId}
                                    onOptionSelect={onSubscriptionSelected}>
                                    {subscriptions?.map((x: any) => {
                                        return <Option
                                            className={classes.option}
                                            key={x.subscriptionId}
                                            value={x.subscriptionId}
                                            text={x.productName}
                                        >{x.productName}</Option>
                                    })}
                                </Dropdown>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </EngageTable>

            </div>
        </>
    );
}