export const STATUSES = {
    IN_PROGRESS: 'In Progress',
    NEW: 'New',
    ASSIGNED: 'Assigned',
    ON_HOLD: 'On Hold',
    RESOLVED: 'Resolved',
    CLOSED: 'Closed'
}

export const STATUS_TYPES = {
    OPEN: 'Open',
    CLOSED: 'Closed'
}

// Not really a constant, but if we have more configuration items we'll move them to a different file
export const scopes = [
    "openid",
    "profile",
    process.env.REACT_APP_API_SCOPE as string
    //process.env.REACT_APP_AAD_APP_CLIENT_ID
];

export const CLIENTS = {
    DESKTOP: 'desktop',
    WEB: 'web',
    IOS: 'ios',
    IPAD: 'ipados',
    ANDROID: 'android',
}

export const ERROR_CODES = {
    ACCOUNT_NOT_FOUND: "account_not_found",
    UNSUPPORTED_CLIENT_TYPE: "unsupported_client_type",
    SERVICE_UNAVAILABLE: "service_unavailable",
}

export const GRAPH_PERMISSIONS = [
    {
        name: "Organization.Read.All",
        description: "Used to pull the Graph API Subscribed SKUs endpoint"
    }
]

export const APPLICATION_ROLES = {
    SERVICE_MANAGER: 'ServiceManager.Read'
}

export const ENGAGE_USER_ROLES = {
    ADMINISTRATOR: 'Administrator',
    PAX8_LICENSING_ADMIN: 'Pax8LicensingAdmin',
    PAX8_LICENSING_MANAGER: 'Pax8LicensingManager'
}