import {
    tokens
} from '@fluentui/react-components'

import {
    HorizontalBarChart,
    IHorizontalBarChartStyles
}
    from '@fluentui/react-charting';
import StyledCard from '../StyledCard';
import NoData from './NoData';

function TopTicketSubmitters({ highestTicketSubmitterData, className, maxRenderSize }: any) {
    const total = highestTicketSubmitterData.totalTickets;


    const data = highestTicketSubmitterData.topTicketSubmitters.map((employee: any) => {
        return {
            chartTitle: employee.name,
            chartData: [{
                legend: employee.name,
                horizontalBarChartdata: { x: employee.value, y: total },
                color: tokens.colorBrandForeground1
            }]
        }
    }).slice(0, maxRenderSize);

    const horizontalBarChartStyles: IHorizontalBarChartStyles = {
        chartTitleRight: {
            color: tokens.colorNeutralForeground1
        },
        chartTitle: {
            color: tokens.colorNeutralForeground1
        },
        root: {},
        items: {},
        chart: {},
        chartWrapper: {},
        barWrapper: {}, 
        chartTitleLeft: { color: tokens.colorNeutralForeground1 },
        chartDataTextDenominator: {},
        benchmarkContainer: {},
        triangle: {},
        barLabel: {}
    }

    return (
        <>
            <StyledCard title="Top Ticket Submitters" className={className}>
                {data.length === 0 ? <NoData /> :
                    <HorizontalBarChart
                        styles={horizontalBarChartStyles}
                        data={data}
                    />
                }
            </StyledCard>
        </>
    );
}

export default TopTicketSubmitters;